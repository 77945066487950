import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import './about-us.scss'
import './mission.scss'

const Mission = () => (
    <Layout>
        <SEO title='Mission' />
        <h2 style={{ margin: '1em 0' }}>理念</h2>
        <div className='line-dot'></div>
        <div className='mission-section'>
            <h3 className='aboutus-h3'>Mission /　任務・使命</h3>
            <p className='mission-p'>あらゆる人達が主体的に地域の未来を担い合うために、必要な資源を循環させる仕組みをつくり、
            地域で支え合う社会実現の一旦を担う</p>
        </div>
        <div className='line-dot'></div>
        <div className='mission-section'>
            <h3 className='aboutus-h3'>Vision / 構想・未来像</h3>
            <p className='mission-p'>それぞれの想いと社会の架け橋になる会社</p>
        </div>
        <div className='line-dot'></div>
        <div className='mission-section'>
            <h3 className='aboutus-h3'>Value / 価値</h3>
            <p className='mission-p'>マイノリティ・コラボ</p>
            <p className='mission-p'>地域コミュニティ</p>
            <p className='mission-p' style={{ marginBottom: '0.5em' }}>エデュケーション</p>
        </div>
        <div className='line-dot'></div>

    </Layout>
)

export default Mission